import { FC } from 'react'

import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { ChevronRightIcon } from '@heroicons/react/24/solid'

import { navigate } from '@redwoodjs/router'

import useAnalytics from 'src/lib/hooks/useAnalytics'
import TheHubIcon from 'src/lib/icons/TheHubIcon/TheHubIcon'

import Button from '../Library/Button/Button'

export interface ButtonOption {
  label: string
  url: string
}

interface OnboardingSubNavigationProps {
  title: string
  backButton: ButtonOption
  nextButton: ButtonOption
}

const OnboardingSubNavigation: FC<OnboardingSubNavigationProps> = ({
  title,
  backButton,
  nextButton,
}) => {
  const { trackEvent } = useAnalytics()
  return (
    <>
      <div className="max-w-[300px] mx-auto ">
        <TheHubIcon />
        <p className="text-center text-2xl py-3">{title}</p>
      </div>

      <div className="flex gap-4 items-center justify-between">
        <Button
          variant="text"
          fullWidth={false}
          className="text-gray-600 hover:bg-gray-100 px-3"
          onClick={() => {
            navigate(backButton.url)
            trackEvent('Settings', `OB ${title} click ${backButton.label} `)
          }}
          startIcon={<ChevronLeftIcon className="w-5 h-5" />}
        >
          <span className="pt-0.5">{backButton.label}</span>
        </Button>
        <Button
          variant="text"
          fullWidth={false}
          className="text-gray-600 hover:bg-gray-100 px-3"
          onClick={() => {
            navigate(nextButton.url)
            trackEvent('Settings', `OB ${title} click ${nextButton.label} `)
          }}
          endIcon={<ChevronRightIcon className="w-5 h-5" />}
        >
          <span className="pt-0.5">{nextButton.label}</span>
        </Button>
      </div>
    </>
  )
}

export default OnboardingSubNavigation
